/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/quiz.css";
import "../less/repeat-content.css";
import "../less/test.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PlayContentPage(props) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  //const previousStatus = props.location.state.status
  const quiz_deck_id = props.match.params.quizdeckId;

  const [currentStatus, setCurrentStatus] = useState();
  const [currentQuizTakenId, setCurrentQuizTakenId] = useState();
  const [originalQuizTakenId, setOriginalQuizTakenId] = useState();

  const [quizTakenId, setQuizTakenId] = useState();

  let localCorrectAnswers = 0;
  let localWrongAnswers = 0;
  let localSkippedAnswers = 0;

  let previouslySubmittedAnswers = [];
  let previousanswerdataset = [];

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let QUESTIONS_PATH = awsImageUrl + "/uploads/questions/";
  let ANSWERS_PATH = awsImageUrl + "/uploads/answers/";
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  const initialTime = 100000;

  const [questionList, setQuestionList] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [currentQuestionNo, setCurrentQuestionNo] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [currentSelectedAnswerIndex, setCurrentSelectedAnswerIndex] =
    useState();
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState();
  const [inputAnswer, setInputAnswer] = useState();
  const [inputCorrectAnswer, setInputCorrectAnswer] = useState();
  const [currentQuestionSelectedAnswer, setCurrentQuestionSelectedAnswer] =
    useState();
  const [currentQuestionCorrectAnswer, setCurrentQuestionCorrectAnswer] =
    useState();
  const [currentQuesAnswerOptionMode, setCurrentQuesAnswerOptionMode] =
    useState();
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [questionsOrComparision, setQuestionsorComparision] = useState(0);
  const [selectedAnswerObject, setSelectedAnswerObject] = useState();
  const [correctAnswerObject, setCorrectAnswerObject] = useState();
  const [totalCorrectAnswers, setTotalCorrectAnswers] =
    useState(localCorrectAnswers);
  const [totalWrongAnswers, setTotalWrongAnswers] = useState(localWrongAnswers);
  const [totalSkippedAnswers, setTotalSkippedAnswers] =
    useState(localSkippedAnswers);
  const [answerResultsAfterSubmission, setAnswerResultsAfterSubmission] =
    useState(previousanswerdataset);
  const [isQuizFullyCompleted, setIsQuizFullyCompleted] = useState(false);
  const [wholeResult, setWholeResult] = useState([]);
  const [ratingStars, setRatingStars] = useState(0);
  const [review, setReview] = useState();

  const [isReviewedFromBefore, setIsReviewedFromBefore] = useState(null);

  const [globalPreviousAnswers, setGlobalPreviousAnswers] = useState([]);
  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);
  const [quizDeckTitle, setQuizDeckTitle] = useState();
  const [modalShown, setModalShown] = useState(false);

  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth <= 480); // Define mobile width threshold

  const user = cookies["user"]; //getUser();
  const user_id = user.id;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const desktopQuery = window.matchMedia("(min-width: 992px)");
    const tabletQuery = window.matchMedia(
      "(min-width: 768px) and (max-width: 991px)"
    );
    const mobileQuery = window.matchMedia("(max-width: 767px)");
    const handleMediaChange = () => {
      setIsDesktop(desktopQuery.matches);
      setIsTablet(tabletQuery.matches);
      setIsMobile(mobileQuery.matches);
    };

    desktopQuery.addEventListener("change", handleMediaChange);
    tabletQuery.addEventListener("change", handleMediaChange);
    mobileQuery.addEventListener("change", handleMediaChange);

    handleMediaChange();

    return () => {
      desktopQuery.removeEventListener("change", handleMediaChange);
      tabletQuery.removeEventListener("change", handleMediaChange);
      mobileQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setIsSmallMobile(window.innerWidth <= 480);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/quiz/play/lateststatus",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        if (response.data.quiz_taken_id) {
          setCurrentQuizTakenId(response.data.quiz_taken_id);
        }
        if (response.data.quizDetails && response.data.quizDetails.length > 0) {
          setQuizDeckTitle(response.data.quizDetails["0"]["name"]);
        }
        setCurrentStatus(response.data.status);
        // setLoading(false)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    if (currentStatus == 0 && !currentQuizTakenId) {
      axios({
        method: "post",
        url: "/api/quiz/play/change/status",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          language_id: globalLangId,
          quiz_deck_id: quiz_deck_id,
          status: 1,
        }),
      })
        .then((response) => {
          if (response.data.quiz_taken_id) {
            setOriginalQuizTakenId(response.data.quiz_taken_id);
          }
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }

    if (currentStatus == 1 && currentQuizTakenId) {
      axios({
        method: "post",
        url: "/api/quiz/play/change/status",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          language_id: globalLangId,
          quiz_deck_id: quiz_deck_id,
          status: 3,
          quiz_taken_id: currentQuizTakenId,
        }),
      })
        .then((response) => {
          if (response.data.submitted_answers) {
            previouslySubmittedAnswers = response.data.submitted_answers;
            setGlobalPreviousAnswers(previouslySubmittedAnswers);
          }
          if (response.data.quiz_taken_id) {
            setOriginalQuizTakenId(response.data.quiz_taken_id);
          }

          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  }, [currentStatus, currentQuizTakenId]);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user_id,
      }),
    })
      .then((response) => {
        setAdLength(response.data.total);
      })
      .catch((error) => {});
  }, []),
    useEffect(() => {
      if (adLength > 0) {
        setAdv([]);
        for (let i = 0; i < 2; i++) {
          let randomPosition = getRandomInt(0, adLength - 1);
          axios({
            method: "post",
            url: "/api/advertisements/list",
            headers: {
              locale: globalLangCode,
            },
            data: qs.stringify({
              position: randomPosition,
              size: 1,
              user_id: user.id,
            }),
          })
            .then((response) => {
              setAdv((ad) => ad.concat(response.data.data));
            })
            .catch((error) => {
              // removeUserSession();
            });
        }
      }
    }, [adLength]),
    useEffect(() => {
      if (originalQuizTakenId && globalPreviousAnswers) {
        setQuestionList([]);
        axios({
          method: "post",
          url: "/api/quiz/play/questions/list",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-access-token": "Memo " + token,
            locale: globalLangCode,
          },
          data: qs.stringify({
            language_id: globalLangId,
            quiz_deck_id: quiz_deck_id,
            quiz_taken_id: originalQuizTakenId,
          }),
        })
          .then((response) => {
            let tempQuestionArray = [];
            if (globalPreviousAnswers.length == response.data.data.length) {
              setIsQuizFullyCompleted(true);
            }
            for (let i = 0; i < response.data.data.length; i++) {
              let questionfound = 0;
              if (globalPreviousAnswers.length > 0) {
                for (let j = 0; j < globalPreviousAnswers.length; j++) {
                  if (
                    response.data.data[i].id ==
                    globalPreviousAnswers[j].question_id
                  ) {
                    questionfound = 1;

                    break;
                  } else {
                    questionfound = 0;
                  }
                }
              }
              if (questionfound == 0) {
                tempQuestionArray.push(response.data.data[i]);
              }
            }
            setQuestionList(tempQuestionArray);

            if (globalPreviousAnswers.length > 0) {
              for (let i = 0; i < globalPreviousAnswers.length; i++) {
                if (
                  globalPreviousAnswers[i].selected_options ==
                  globalPreviousAnswers[i].right_options
                ) {
                  localCorrectAnswers++;
                } else if (
                  globalPreviousAnswers[i].selected_options &&
                  globalPreviousAnswers[i].selected_options !=
                    globalPreviousAnswers[i].right_options
                ) {
                  localWrongAnswers++;
                } else if (!globalPreviousAnswers[i].selected_options) {
                  localSkippedAnswers++;
                }
                previousanswerdataset.push({
                  question_id: parseInt(globalPreviousAnswers[i].question_id),
                  id: parseInt(globalPreviousAnswers[i].answer_id),
                });
              }
              setTotalCorrectAnswers(localCorrectAnswers);
              setTotalWrongAnswers(localWrongAnswers);
              setTotalSkippedAnswers(localSkippedAnswers);
              setAnswerResultsAfterSubmission(previousanswerdataset);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, [originalQuizTakenId, globalPreviousAnswers]);

  const { t } = useTranslation();

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getQuestionDescriptionFontSize = (description) => {
    const questionLength = (description ?? "").length;
    if (isDesktop) {
      if (questionLength < 200) {
        return "34px !important";
      }

      if (questionLength < 400) {
        return "30px !important";
      }

      return "24px !important";
    }

    if (isTablet) {
      if (questionLength < 200) {
        return "34px !important";
      }

      if (questionLength < 400) {
        return "24px !important";
      }

      return "20px !important";
    }

    if (isMobile) {
      return questionLength < 200 ? "20px !important" : "16px !important";
    }
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (questionList) {
      setNumberOfQuestions(questionList.length);
    }
  }, [questionList]);

  useEffect(() => {
    if (numberOfQuestions) {
      setCurrentQuestionNo(0);
    }
  }, [numberOfQuestions]);

  useEffect(() => {
    if (currentQuestionNo >= 0) {
      if (questionsOrComparision == 1) {
        setQuestionsorComparision(0);
      }
      setCurrentSelectedAnswerIndex();
      setCurrentQuestionSelectedAnswer();
      setSelectedAnswerObject();
      setInputCorrectAnswer();
      setInputAnswer();
      setProgressBarWidth(
        Math.round(100 * ((currentQuestionNo + 1) / numberOfQuestions))
      );
      setCurrentQuestionId(questionList[currentQuestionNo].id);
      const name = questionList[currentQuestionNo].name;
      setCurrentQuestion({
        ...questionList[currentQuestionNo],
        name:
          `<style>
            .quiz_desc * {
              font-size: ${getQuestionDescriptionFontSize(name)};
              font-family: Arial;
            }
          </style>` + name,
      });
      setCurrentQuesAnswerOptionMode(
        questionList[currentQuestionNo].ans_mode_id
      );
    }
  }, [currentQuestionNo]);

  useEffect(() => {
    //if(currentQuestion && modalShown) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image&async=true";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
    //}
  }, [currentQuestion, modalShown]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter") {
        // Don't trigger if user is typing in a text input or textarea
        if (
          event.target.tagName.toLowerCase() === "textarea" ||
          event.target.tagName.toLowerCase() === "input"
        ) {
          return;
        }

        // Make sure currentQuestionId and currentQuestionCorrectAnswer exist before proceeding
        if (!currentQuestionId && currentQuestion) {
          setCurrentQuestionId(currentQuestion.id);
          await new Promise((resolve) => setTimeout(resolve, 0));
        }

        // Set correct answer if not already set
        if (currentQuestion && !currentQuestionCorrectAnswer) {
          for (let i = 0; i < currentQuestion.answers.length; i++) {
            if (currentQuestion.answers[i].is_right_answer === 1) {
              setCurrentQuestionCorrectAnswer(currentQuestion.answers[i].id);
              await new Promise((resolve) => setTimeout(resolve, 0));
              break;
            }
          }
        }

        // Now handle the different scenarios
        if (isQuizFullyCompleted) {
          if (answerResultsAfterSubmission.length === numberOfQuestions) {
            submitQuiz();
          }
        } else if (questionsOrComparision === 0) {
          submitCurrentQuestionAndAnswer();
        } else if (questionsOrComparision === 1) {
          moveToNextQuestion();
        }
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => window.removeEventListener("keypress", handleKeyPress);
  }, [
    isQuizFullyCompleted,
    questionsOrComparision,
    answerResultsAfterSubmission.length,
    numberOfQuestions,
    currentQuestionId,
    currentQuestion,
    currentQuestionCorrectAnswer,
  ]);

  const onHintClick = () => {
    document.getElementById("id-hint").click();
    document.getElementById("hintModal").style = "display:block";
    setTimeout(() => {
      setModalShown(true);
    }, 1000);
  };
  const hideHintModalClick = () => {
    document.getElementById("hintModal").style = "display:none";
  };
  const onExplainClick = () => {
    document.getElementById("id-explain").click();
    document.getElementById("explainModal").style = "display:block";
    setTimeout(() => {
      setModalShown(true);
    }, 1000);
  };
  const hideExplainModalClick = () => {
    document.getElementById("explainModal").style = "display:none";
  };
  useEffect(() => {
    if (currentQuestion) {
      setCurrentSelectedAnswerIndex();
      // document.getElementById("correctAnswerMessage").style.display = "none"
      for (let i = 0; i < currentQuestion.answers.length; i++) {
        if (currentQuestion.answers[i].is_right_answer === 1) {
          setCurrentQuestionCorrectAnswer(currentQuestion.answers[i].id);
          setCorrectAnswerObject(currentQuestion.answers[i]);
          setCorrectAnswerIndex(i + 1);
          setInputCorrectAnswer(currentQuestion.answers[i].name);
        }
      }
    }
  }, [currentQuestion]);

  const markThisAsAnswer = (answerId, index, answerObject) => {
    if (currentSelectedAnswerIndex == index) {
      setSelectedAnswerObject();
      setCurrentSelectedAnswerIndex();
      setCurrentQuestionSelectedAnswer();
    } else {
      setSelectedAnswerObject(answerObject);
      setCurrentSelectedAnswerIndex(index);
      setCurrentQuestionSelectedAnswer(answerId);
    }
  };

  const removeQuestionToBeAskedFromContent = async () => {
    await axios({
      method: "post",
      url: "/api/my/quiz/question/remove",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
        question_id: currentQuestionId,
        quiz_taken_id: originalQuizTakenId,
      }),
    })
      .then((response) => {
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const addCurrentQuestionToRepeat = async () => {
    await axios({
      method: "post",
      url: "/api/my/repeat/quizzes/questions/add",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
        question_id: currentQuestionId,
      }),
    })
      .then((response) => {
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const submitCurrentQuestionAndAnswer = () => {
    let input;
    if (currentQuesAnswerOptionMode === "1") {
      input = inputAnswer;
    }
    let toBeSubmittedAnswerDataset = {
      quiz_taken_id: originalQuizTakenId,
      language_id: 1,
      question_id: currentQuestionId,
      quiz_deck_id: quiz_deck_id,
      answer_mode: currentQuesAnswerOptionMode,
      points: 0,
      option_mode: currentQuesAnswerOptionMode,
      right_options: currentQuestionCorrectAnswer,
      topic_id: 0,
      status: 2,
    };
    if (currentQuesAnswerOptionMode == 1) {
      if (inputAnswer && inputAnswer === inputCorrectAnswer) {
        toBeSubmittedAnswerDataset.selected_options =
          currentQuestionCorrectAnswer;
      } else if (inputAnswer && inputAnswer !== inputCorrectAnswer) {
        toBeSubmittedAnswerDataset.selected_options = "00000000";
      }
    } else {
      toBeSubmittedAnswerDataset.selected_options =
        currentQuestionSelectedAnswer;
    }

    if (
      toBeSubmittedAnswerDataset.selected_options ===
        toBeSubmittedAnswerDataset.right_options ||
      inputAnswer === inputCorrectAnswer
    ) {
      setTotalCorrectAnswers(totalCorrectAnswers + 1);
      // alert ("Correct Answer")
      // document.getElementById("correctAnswerMessage").style.display = "block"
    } else if (
      selectedAnswerObject === undefined &&
      inputAnswer !== undefined &&
      inputAnswer !== inputCorrectAnswer
    ) {
      setTotalWrongAnswers(totalWrongAnswers + 1);
    } else if (selectedAnswerObject !== undefined) {
      setTotalWrongAnswers(totalWrongAnswers + 1);
    } else {
      setTotalSkippedAnswers(totalSkippedAnswers + 1);
    }

    axios({
      method: "post",
      url: "/api/quiz/play/submit/answer",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify(toBeSubmittedAnswerDataset),
    })
      .then((response) => {
        setAnswerResultsAfterSubmission((answerResultsAfterSubmission) =>
          answerResultsAfterSubmission.concat({
            question_id: currentQuestionId,
            id: response.data.answer_id,
          })
        );
        let localObject = {
          selectedAnswerObject: selectedAnswerObject,
          correctAnswerObject: correctAnswerObject,
          selectedAnswerIndex: currentSelectedAnswerIndex + 1,
          correctAnswerIndex: correctAnswerIndex,
          question: currentQuestion,
        };
        if (currentQuesAnswerOptionMode === "1") {
          localObject.inputAnswer = inputAnswer;
          localObject.inputCorrectAnswer = inputCorrectAnswer;
        }
        setWholeResult((wholeResult) => wholeResult.concat(localObject));
      })
      .catch((error) => {
        console.log(error);
      });

    if (questionsOrComparision == 0) {
      setQuestionsorComparision(1);
    }
  };

  const moveToNextQuestion = () => {
    if (currentQuestionNo < numberOfQuestions - 1) {
      setCurrentQuestionNo(currentQuestionNo + 1);
    } else if (currentQuestionNo == numberOfQuestions - 1) {
      setIsQuizFullyCompleted(true);
    }
  };

  const skipReview = () => {
    goToResultsPage();
  };

  const submitReview = async () => {
    await axios({
      method: "post",
      url: "/api/quiz/review/create",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        rating_for: 1,
        to_user_id: 0,
        review: review,
        rating: ratingStars,
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        showMessageToUser(response.data.msg);
        goToResultsPage();
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const submitQuiz = () => {
    if (isReviewedFromBefore === 0) {
      document.getElementById("modalOpenButton").click();
    } else {
      axios({
        method: "post",
        url: "/api/quiz/play/quiz/complete",
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: JSON.stringify({
          quiz_deck_id: quiz_deck_id,
          topic_id: 0,
          quiz_taken_id: originalQuizTakenId,
          language_id: globalLangId,
          total_right_answers: totalCorrectAnswers,
          total_wrong_answers: totalWrongAnswers,
          total_skipped_answers: totalSkippedAnswers,
          points: totalCorrectAnswers,
          accuracy: parseInt(
            (totalCorrectAnswers / answerResultsAfterSubmission.length) * 100
          ),
          results: answerResultsAfterSubmission,
        }),
      })
        .then((response) => {
          setIsReviewedFromBefore(response.data.is_reviewed);
          if (response.data.is_reviewed == 1) {
            goToResultsPage();
          } else {
            document.getElementById("modalOpenButton").click();
          }
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  };

  const goToResultsPage = () => {
    window.localStorage.setItem(
      "PLAY_CONTENT_PAGE_STATE",
      JSON.stringify({
        ...props.location.state,
        quiz_taken_id: originalQuizTakenId,
        total_right_answers: totalCorrectAnswers,
        total_wrong_answers: totalWrongAnswers,
        total_skipped_answers: totalSkippedAnswers,
        numberOfQuestions: answerResultsAfterSubmission.length,
        accuracy: parseInt(
          (totalCorrectAnswers / answerResultsAfterSubmission.length) * 100
        ),
        wholeResult: wholeResult,
      })
    );

    props.history.push({
      pathname: "/contentresults/" + quiz_deck_id,
    });

    /*
        props.history.push({
            pathname: '/contentresults/' + quiz_deck_id,
            state: {
                ...props.location.state,
                quiz_taken_id: originalQuizTakenId,
                total_right_answers: totalCorrectAnswers,
                total_wrong_answers: totalWrongAnswers,
                total_skipped_answers: totalSkippedAnswers,
                numberOfQuestions: answerResultsAfterSubmission.length,
                accuracy: parseInt((totalCorrectAnswers / answerResultsAfterSubmission.length) * 100),
                wholeResult: wholeResult
            }
        })
        */
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row mb-30px">
            <div class="col-sm-5 col-md-6 col-lg-6">
              {/* <h2 class="animated fadeInLeft">{t("play_flash_quiz")}</h2> */}
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("play_flash_quiz")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {quizDeckTitle ? (
            <div class="memorizeTitle mb-2">
              <span dangerouslySetInnerHTML={{ __html: quizDeckTitle }}></span>
            </div>
          ) : null}
        </div>
      </div>
      <div class="container" style={{ display: "none" }}>
        <div class="row my-2 justify-content-center">
          <div class="col-md-5 col-sm-7">
            <div class="py-3 px-1 blueBg text-white text-center">
              <span class="d-block">Become a sponsor of a</span>
              <span class="d-block mb-2px">Memorizeitall pro account :</span>
              <span class="d-block font-weight-bold">
                Memorizeitall - <span>www.memorizeitall.com</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <section class="gener_gap">
        <div class="">
          <div class="">
            <div className="w-100 d-flex justify-content-center">
              <div class="bar_container">
                <div
                  class="skills html"
                  style={{ width: progressBarWidth + "%" }}
                >
                  {progressBarWidth}%
                </div>
              </div>
            </div>

            {/* <span className="prize icon-cup"></span> */}

            {currentQuestion ? (
              <React.Fragment>
                <div class="">
                  <div class="col-md-12 quizContainer">
                    <div class="play-quiz-section">
                      <div class="col-md-9 mr-auto">
                        {isQuizFullyCompleted === false &&
                        (currentQuestion.hint ||
                          currentQuestion.explanation) ? (
                          <div class="row cust_icns justify-content-md-center">
                            {currentQuestion.hint ? (
                              <div class="col-md-4 col-4">
                                <div
                                  onClick={() => onHintClick()}
                                  id="id-hint"
                                  class="img"
                                  data-toggle="modal"
                                  data-target="#hintModal"
                                >
                                  {/* <img src={bulbImg} /> */}
                                  <span className="icon icon-bulb"></span>
                                </div>
                                <h6 class="support_link">{t("hints")}</h6>
                              </div>
                            ) : null}
                            {currentQuestion.explanation ? (
                              <div class="col-md-4 col-4 p-0">
                                <div
                                  onClick={() => onExplainClick()}
                                  id="id-explain"
                                  class="img"
                                  data-toggle="modal"
                                  data-target="#explanModal"
                                >
                                  {/* <img src={jarImg} /> */}
                                  <span className="icon icon-explanation"></span>
                                </div>
                                <h6 class="support_link">{t("explanation")}</h6>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>

                      {isQuizFullyCompleted === false ? (
                        <div
                          class={`temp_qa_container d-flex ${
                            isMobile ? "flex-column" : "flex-row"
                          }`}
                        >
                          <div class="temp_question_section col-lg-6 col-md-6 col-sm-12">
                            {currentQuestion.qus_mode_id == 1 ? (
                              <div class="d-flex flex-column ">
                                <h5 class="quiz_head">
                                  <strong>
                                    {t("question")} {currentQuestionNo + 1}:
                                  </strong>{" "}
                                </h5>
                                <p
                                  class="quiz_desc"
                                  style={{
                                    fontSize: getQuestionDescriptionFontSize(
                                      currentQuestion.name
                                    ),
                                  }}
                                  dangerouslySetInnerHTML={
                                    currentQuestion.name
                                      ? { __html: currentQuestion.name }
                                      : null
                                  }
                                ></p>
                              </div>
                            ) : null}
                            <div class="temp_options d-flex flex-column justify-content-center">
                              {currentQuestion.qus_mode_id == 2 ? (
                                <div class="d-flex flex-column">
                                  <h5 class="quiz_head">
                                    <strong>
                                      {t("question")} {currentQuestionNo + 1}:
                                    </strong>{" "}
                                  </h5>
                                  <p
                                    class="quiz_desc"
                                    dangerouslySetInnerHTML={
                                      currentQuestion.name
                                        ? { __html: currentQuestion.name }
                                        : null
                                    }
                                  ></p>

                                  <div class="answer-section text-center">
                                    <img
                                      src={
                                        QUESTIONS_PATH +
                                        currentQuestion.file_path
                                      }
                                    ></img>
                                  </div>
                                </div>
                              ) : null}

                              {currentQuestion.qus_mode_id == 4 ||
                              currentQuestion.qus_mode_id == 3 ? (
                                <div class="d-flex flex-column">
                                  <h5 class="quiz_head">
                                    <strong>
                                      {t("question")} {currentQuestionNo + 1}:
                                    </strong>{" "}
                                  </h5>
                                  <p
                                    class="quiz_desc"
                                    dangerouslySetInnerHTML={
                                      currentQuestion.name
                                        ? { __html: currentQuestion.name }
                                        : null
                                    }
                                  ></p>
                                  {/*<p>{QUESTIONS_PATH +  currentQuestion.file_path}</p>*/}
                                  {currentQuestion.qus_mode_id === "3" ? (
                                    <div className="repeatplayaudio">
                                      <ReactPlayer
                                        width="100%"
                                        //height="100%"
                                        url={
                                          QUESTIONS_PATH +
                                          currentQuestion.file_path
                                        }
                                        controls={true}
                                        // Disable download button
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                        // Disable right click
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="repeatplayaudio repeatplayaudio1">
                                      <ReactPlayer
                                        width="100%"
                                        //height="100%"
                                        url={
                                          QUESTIONS_PATH +
                                          currentQuestion.file_path
                                        }
                                        controls={true}
                                        // Disable download button
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                        // Disable right click
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <div
                                class="details-play-quiz"
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                }}
                                onClick={
                                  questionsOrComparision == 0
                                    ? submitCurrentQuestionAndAnswer
                                    : moveToNextQuestion
                                }
                              >
                                <span class="rightArrow">›</span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  position: "absolute",
                                  right: 0,
                                }}
                              >
                                {questionsOrComparision == 1 ? (
                                  <div class="cmplt-preview repeat-add-btn">
                                    <a
                                      id="id_repeat_page"
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={t("add_repeat_question")}
                                      onClick={() =>
                                        addCurrentQuestionToRepeat()
                                      }
                                    >
                                      <span class="icon-refresh-add"></span>
                                    </a>
                                    <a
                                      id="id_repeat_page"
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={t(
                                        "remove_question_to_be_asked_from_this_content"
                                      )}
                                      onClick={() =>
                                        removeQuestionToBeAskedFromContent()
                                      }
                                    >
                                      <span class="icon-refresh-minus"></span>
                                    </a>
                                  </div>
                                ) : null}
                                <div
                                  class="cmplt-preview repeat-add-btn"
                                  style={{
                                    display:
                                      questionsOrComparision == 0
                                        ? "unset"
                                        : "none",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div class="temp_answer_section  col-lg-6 col-md-6 col-sm-12">
                            {questionsOrComparision == 0 ? (
                              <div class="d-flex flex-column ">
                                <h5
                                  class="quiz_head"
                                  style={
                                    currentQuestion.ans_mode_id === "1"
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <strong>{t("write_your_answer")}</strong>
                                </h5>
                                <h5
                                  class="quiz_desc"
                                  style={
                                    currentQuestion.ans_mode_id !== "1"
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <strong>{t("options")} :</strong>
                                </h5>
                                {currentQuestion.ans_mode_id === "1"
                                  ? currentQuestion.answers.map(
                                      (eachAnswer, index) => (
                                        <div class="answer-section">
                                          <p>
                                            <span class="answerStatusIcon">
                                              <i class="fa fa-check-circle"></i>
                                            </span>
                                            <span class="answerStatusText">
                                              <textarea
                                                class="form-control"
                                                autoFocus
                                                onKeyPress={(e) => {
                                                  e.key === "Enter" &&
                                                    e.preventDefault();
                                                }}
                                                onChange={(e) => {
                                                  setInputAnswer(
                                                    e.target.value
                                                  );
                                                }}
                                              ></textarea>
                                            </span>
                                          </p>
                                        </div>
                                      )
                                    )
                                  : null}
                                {currentQuestion.ans_mode_id === "2"
                                  ? currentQuestion.answers.map(
                                      (eachAnswer, index) => (
                                        <div
                                          class={
                                            currentSelectedAnswerIndex == index
                                              ? "answer-section active"
                                              : "answer-section"
                                          }
                                          onClick={() =>
                                            markThisAsAnswer(
                                              eachAnswer.id,
                                              index,
                                              eachAnswer
                                            )
                                          }
                                        >
                                          <p>
                                            <span class="answerStatusIcon">
                                              <i class="fa fa-check-circle"></i>
                                            </span>
                                            <span
                                              class="opt_tetx answerStatusText"
                                              dangerouslySetInnerHTML={{
                                                __html: eachAnswer.name,
                                              }}
                                            ></span>
                                          </p>
                                          {/* <p class="opt_tetx" dangerouslySetInnerHTML={{ __html: eachAnswer.name }}></p> */}
                                        </div>
                                      )
                                    )
                                  : null}
                                {/* answer images goes here */}
                                <div className="temp_answer_image">
                                  {currentQuestion.ans_mode_id === "3"
                                    ? currentQuestion.answers.map(
                                        (eachAnswer, index) => (
                                          <div
                                            key={eachAnswer.id}
                                            className={`answer-item ${
                                              currentSelectedAnswerIndex ==
                                              index
                                                ? "answer-section active text-center"
                                                : "answer-section text-center"
                                            }`}
                                            onClick={() =>
                                              markThisAsAnswer(
                                                eachAnswer.id,
                                                index,
                                                eachAnswer
                                              )
                                            }
                                          >
                                            <p style={{ margin: 0 }}>
                                              <span className="answerStatusIcon">
                                                <i className="fa fa-check-circle"></i>
                                              </span>
                                              <span className="answerStatusText">
                                                <img
                                                  className="answer-image"
                                                  src={
                                                    ANSWERS_PATH +
                                                    eachAnswer.file_path
                                                  }
                                                />
                                              </span>
                                            </p>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div>
                                {currentQuestion.ans_mode_id === "4" ||
                                currentQuestion.ans_mode_id === "5"
                                  ? currentQuestion.answers.map(
                                      (eachAnswer, index) => (
                                        <div
                                          class={
                                            currentSelectedAnswerIndex == index
                                              ? "answer-section active"
                                              : "answer-section"
                                          }
                                          onClick={() =>
                                            markThisAsAnswer(
                                              eachAnswer.id,
                                              index,
                                              eachAnswer
                                            )
                                          }
                                        >
                                          <p>
                                            <i class="fa fa-check-circle"></i>
                                          </p>
                                          {/*<p>{ANSWERS_PATH + eachAnswer.file_path}</p> */}
                                          {currentQuestion.ans_mode_id ===
                                          "4" ? (
                                            <div className="repeatplayaudio">
                                              <ReactPlayer
                                                width="100%"
                                                //height="100%"
                                                url={
                                                  ANSWERS_PATH +
                                                  eachAnswer.file_path
                                                }
                                                controls={true}
                                                // Disable download button
                                                config={{
                                                  file: {
                                                    attributes: {
                                                      controlsList:
                                                        "nodownload",
                                                    },
                                                  },
                                                }}
                                                // Disable right click
                                                onContextMenu={(e) =>
                                                  e.preventDefault()
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div className="repeatplayaudio repeatplayaudio1">
                                              <ReactPlayer
                                                width="100%"
                                                //height="100%"
                                                url={
                                                  ANSWERS_PATH +
                                                  eachAnswer.file_path
                                                }
                                                controls={true}
                                                // Disable download button
                                                config={{
                                                  file: {
                                                    attributes: {
                                                      controlsList:
                                                        "nodownload",
                                                    },
                                                  },
                                                }}
                                                // Disable right click
                                                onContextMenu={(e) =>
                                                  e.preventDefault()
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )
                                  : null}
                              </div>
                            ) : (
                              <React.Fragment>
                                {currentQuestion.ans_mode_id === "1" ? (
                                  selectedAnswerObject ||
                                  inputAnswer === inputCorrectAnswer ? (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          inputAnswer == inputCorrectAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong"
                                        }
                                      >
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <p class="opt_tetx">{inputAnswer}</p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <p
                                          class="opt_tetx"
                                          dangerouslySetInnerHTML={{
                                            __html: inputCorrectAnswer,
                                          }}
                                        ></p>
                                      </div>
                                    </React.Fragment>
                                  ) : selectedAnswerObject === undefined &&
                                    inputAnswer !== undefined &&
                                    inputAnswer !== "" &&
                                    inputAnswer !== inputCorrectAnswer ? (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          inputAnswer == inputCorrectAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong"
                                        }
                                      >
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-times-circle"></i>
                                        </span>

                                        <p
                                          class="opt_tetx d-inline"
                                          dangerouslySetInnerHTML={{
                                            __html: inputAnswer,
                                          }}
                                        ></p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>

                                        <p
                                          class="opt_tetx d-inline"
                                          dangerouslySetInnerHTML={{
                                            __html: inputCorrectAnswer,
                                          }}
                                        ></p>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong unanswered"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span class="opt_tetx">
                                            {t("unanswered")}
                                          </span>
                                        </p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span
                                            class="opt_tetx answerStatusText"
                                            dangerouslySetInnerHTML={{
                                              __html: correctAnswerObject.name,
                                            }}
                                          ></span>
                                        </p>
                                        {/* <p class="opt_tetx" ></p> */}
                                      </div>
                                    </React.Fragment>
                                  )
                                ) : null}
                                {currentQuestion.ans_mode_id === "2" ? (
                                  selectedAnswerObject ? (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span
                                            class="opt_tetx answerStatusText"
                                            dangerouslySetInnerHTML={{
                                              __html: selectedAnswerObject.name,
                                            }}
                                          ></span>
                                        </p>
                                        {/* <p class="opt_tetx" ></p> */}
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span
                                            class="opt_tetx answerStatusText"
                                            dangerouslySetInnerHTML={{
                                              __html: correctAnswerObject.name,
                                            }}
                                          ></span>
                                        </p>
                                        {/* <p></p> */}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong unanswered"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span class="opt_tetx">
                                            {t("unanswered")}
                                          </span>
                                        </p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span
                                            class="opt_tetx answerStatusText"
                                            dangerouslySetInnerHTML={{
                                              __html: correctAnswerObject.name,
                                            }}
                                          ></span>
                                        </p>
                                        {/* <p></p> */}
                                      </div>
                                    </React.Fragment>
                                  )
                                ) : null}
                                {currentQuestion.ans_mode_id === "3" ? (
                                  selectedAnswerObject ? (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right text-center"
                                            : "answer-section wrong text-center"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span class="answerStatusText">
                                            <img
                                              src={
                                                ANSWERS_PATH +
                                                selectedAnswerObject.file_path
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right text-center">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span class="answerStatusText">
                                            <img
                                              src={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong unanswered"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span class="opt_tetx">
                                            {t("unanswered")}
                                          </span>
                                        </p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right text-center">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span class="answerStatusText">
                                            <img
                                              src={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    </React.Fragment>
                                  )
                                ) : null}
                                {currentQuestion.ans_mode_id === "4" ||
                                currentQuestion.ans_mode_id === "5" ? (
                                  selectedAnswerObject ? (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                        </p>
                                        {currentQuestion.ans_mode_id === "4" ? (
                                          <div className="repeatplayaudio">
                                            <ReactPlayer
                                              width="100%"
                                              url={
                                                ANSWERS_PATH +
                                                selectedAnswerObject.file_path
                                              }
                                              controls={true}
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div className="repeatplayaudio repeatplayaudio1">
                                            <ReactPlayer
                                              width="100%"
                                              url={
                                                ANSWERS_PATH +
                                                selectedAnswerObject.file_path
                                              }
                                              controls={true}
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                        </p>
                                        {currentQuestion.ans_mode_id === "4" ? (
                                          <div className="repeatplayaudio">
                                            <ReactPlayer
                                              width="100%"
                                              //height="100%"
                                              url={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                              controls={true}
                                            />
                                          </div>
                                        ) : (
                                          <div className="repeatplayaudio repeatplayaudio1">
                                            <ReactPlayer
                                              width="100%"
                                              //height="100%"
                                              url={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                              controls={true}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p class="your_ans">{t("your_answer")}</p>
                                      <div
                                        class={
                                          currentQuestionCorrectAnswer ==
                                          currentQuestionSelectedAnswer
                                            ? "answer-section right"
                                            : "answer-section wrong unanswered"
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i
                                              class={
                                                currentQuestionCorrectAnswer ==
                                                currentQuestionSelectedAnswer
                                                  ? "fa fa-check-circle"
                                                  : "fa fa-times-circle"
                                              }
                                            ></i>
                                          </span>
                                          <span class="opt_tetx">
                                            {t("unanswered")}
                                          </span>
                                        </p>
                                      </div>
                                      <p class="your_ans">
                                        {t("corerct_answer")}
                                      </p>
                                      <div class="answer-section right">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                        </p>
                                        {currentQuestion.ans_mode_id === "4" ? (
                                          <div className="repeatplayaudio">
                                            <ReactPlayer
                                              width="100%"
                                              //height="100%"
                                              url={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                              controls={true}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="repeatplayaudio repeatplayaudio1">
                                            <ReactPlayer
                                              width="100%"
                                              //height="100%"
                                              url={
                                                ANSWERS_PATH +
                                                correctAnswerObject.file_path
                                              }
                                              controls={true}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )
                                ) : null}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      ) : (
                        <strong>{t("all_questions_answered")}</strong>
                      )}
                      <div
                        id="correctAnswerMessage"
                        style={{
                          display: "none",
                          backgroundColor: "green",
                          width: "400px",
                        }}
                      >
                        {t("corerct_answer")}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal"
                  id="hintModal"
                  role="dialog"
                  data-dismiss="modal"
                >
                  <div className="modal-dialog">
                    <div class="modal-content cust_modal">
                      <div class="modal-header">
                        <h4 class="modal-title">{t("hints")}</h4>
                        <button
                          onClick={() => hideHintModalClick()}
                          type="button"
                          id="id-close-hintModal"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div class="modal-body">
                        {currentQuestion.hint ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: currentQuestion.hint,
                            }}
                          ></p>
                        ) : (
                          <p>t("no_hints_available")</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal"
                  id="explainModal"
                  role="dialog"
                  data-dismiss="modal"
                >
                  <div class="modal-dialog">
                    <div class="modal-content cust_modal">
                      <div class="modal-header">
                        <h4 class="modal-title">{t("explanation")}</h4>
                        <button
                          onClick={() => hideExplainModalClick()}
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div class="modal-body">
                        {currentQuestion.explanation ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: currentQuestion.explanation,
                            }}
                          ></p>
                        ) : (
                          <p>t("no_exp_available")</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : isQuizFullyCompleted === true ? (
              <strong>{t("all_questions_answered")}</strong>
            ) : null}
          </div>
          <div className="mt-4">
            {adv && adv.length > 0 && questionsOrComparision == 0 ? (
              <div class="row">
                {adv && adv.length > 0 ? (
                  <div class="col-sm-6 col-12 rightBannerColumn">
                    <a class="item" target="_blank" href={adv[0]["link"]}>
                      <img
                        src={ADVERTISEMENT_PATH + adv[0]["image"]}
                        class="img-fluid"
                      />
                    </a>
                  </div>
                ) : null}
                {adv && adv.length > 1 ? (
                  <div class="col-sm-6 col-12 leftBannerColumn">
                    <a class="item" target="_blank" href={adv[1]["link"]}>
                      <img
                        src={ADVERTISEMENT_PATH + adv[1]["image"]}
                        class="img-fluid"
                      />
                    </a>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="row m-0">
            {isQuizFullyCompleted === false ? (
              <div class="d-flex justify-content-center mt-4 col-lg-7 col-md-8 col-sm-12 mr-auto p-relative p-0">
                {/* <a href="#"
                                
                            ><i class="fa fa-arrow-left cust_left" style={questionsOrComparision == 1 ? {display:"none"}:null} ></i></a> */}
                <div class="cmplt-preview">
                  <p
                    class="details-play-quiz"
                    style={{ cursor: "pointer", visibility: "hidden" }}
                    onClick={
                      questionsOrComparision == 0
                        ? submitCurrentQuestionAndAnswer
                        : moveToNextQuestion
                    }
                  >
                    <span class="rightArrow">›</span>
                  </p>
                </div>
                <span style={{ visibility: "hidden" }}>
                  {questionsOrComparision == 1 ? (
                    <div class="cmplt-preview repeat-add-btn">
                      <a
                        id="id_repeat_page"
                        href="javascript:void(0)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={t("add_repeat_question")}
                        onClick={() => addCurrentQuestionToRepeat()}
                      >
                        <span class="icon-refresh-add"></span>
                      </a>
                      <a
                        id="id_repeat_page"
                        href="javascript:void(0)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={t(
                          "remove_question_to_be_asked_from_this_content"
                        )}
                        onClick={() => removeQuestionToBeAskedFromContent()}
                      >
                        <span class="icon-refresh-minus"></span>
                      </a>
                    </div>
                  ) : null}
                  <div
                    class="cmplt-preview repeat-add-btn"
                    style={{
                      display: questionsOrComparision == 0 ? "unset" : "none",
                    }}
                  ></div>
                </span>
              </div>
            ) : (
              <div
                class="d-flex justify-content-center  mt-4 col-lg-7 col-md-8 col-sm-12 mr-auto p-relative"
                style={{ cursor: "pointer" }}
              >
                <div
                  class="cmplt-preview"
                  // onClick={reviewThisQuiz}
                >
                  {answerResultsAfterSubmission.length === numberOfQuestions ? (
                    <p
                      class="details-play-quiz p-relative"
                      onClick={() => submitQuiz()}
                    >
                      <span class="rightArrow">›</span>
                    </p>
                  ) : null}
                </div>
                <p
                  class="details-play-quiz"
                  id="modalOpenButton"
                  data-toggle="modal"
                  data-target="#RateandReview"
                  style={{ display: "none" }}
                >
                  Open Modal
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      <div
        class="modal fade add-rate-review-info"
        id="RateandReview"
        tabindex="-1"
        role="dialog"
        aria-labelledby="RateandReview"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("add_rate_and_review")}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <div class="stars">
                  {ratingStars > 0
                    ? (function star() {
                        let stars = [];
                        for (let i = 1; i <= ratingStars; i++) {
                          stars.push(
                            <span
                              onClick={() => setRatingStars(i)}
                              class="icon-star active"
                            ></span>
                          );
                        }
                        return stars;
                      })()
                    : null}
                  {5 - ratingStars > 0
                    ? (function star() {
                        let stars = [];
                        for (let i = 1; i <= 5 - ratingStars; i++) {
                          stars.push(
                            <span
                              onClick={() => setRatingStars(ratingStars + i)}
                              class="icon-star"
                            ></span>
                          );
                        }
                        return stars;
                      })()
                    : null}
                </div>
                <div>
                  <textarea
                    autoFocus
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    placeholder={t("add_review")}
                  ></textarea>
                </div>
                <div class="save-cancel-btn">
                  <a
                    data-dismiss="modal"
                    onClick={submitReview}
                    href="javascript:void(0)"
                    class="btn-background blue-bg-btn"
                  >
                    {t("submit_reviews")}
                  </a>
                  {/* <a
                                data-dismiss="modal"
                                onClick={skipReview} 
                                href="javascript:void(0)" class="btn-border blue-bg-btn">
                                    {t("skip")}
                            </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default PlayContentPage;
